@import "animations";

// Card layout
.card {
  padding: var(--ds-md-padding);
  border-radius: var(--ds-border-radius);
  background-color: var(--white);
}

.li-card {
  padding: var(--ds-md-padding);
  background-color: var(--white);
  border-bottom: 1px solid var(--border-grey);
}
