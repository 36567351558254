// Overwrite Material UI styles for all components.
:root {
  // Border-radius for <mat-menu>.
  --mat-menu-container-shape: 6px;
}

h2 mat-icon,
h3 mat-icon,
h4 mat-icon,
h5 mat-icon,
h6 mat-icon {
  color: var(--purple);
  transform: scale(0.7);
  opacity: 0.7;
  vertical-align: middle !important;
}

.caption-2 mat-icon {
  transform: scale(0.9);
  opacity: 0.7;
  vertical-align: middle !important;
}

label {
  .mat-icon {
    font-size: 14px !important;
    height: 14px !important;
    width: 14px !important;
    line-height: 14px !important;
  }
}

// Reduce padding for <mat-accordion>.
mat-expansion-panel {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
  .mat-expansion-panel-header {
    // 8px because of the toggle icon.
    padding: 0 8px !important;
  }
}

// Overwrite stepper styles
.mat-vertical-stepper-header {
  padding: 16px !important;
}
.mat-vertical-content-container {
  margin-left: 28px !important;
}
.mat-vertical-content {
  padding: 0 16px 16px 16px !important;
}
@media (max-width: $sm-screen) {
  .mat-vertical-stepper-header {
    padding: 8px !important;
  }
  .mat-vertical-content-container {
    margin-left: 18px !important;
  }
  .mat-vertical-content {
    padding: 0 8px 8px 8px !important;
  }
}

// Change the color of the border of <mat-form-field> outline.
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: #bbb !important;
}

.bottom-sheet-container,
.mat-bottom-sheet-container {
  border-radius: 8px 8px 0 0 !important;
  bottom: 0px;
  margin: 0 !important;
  padding: 0 !important;
  min-width: 100vw !important;
  min-width: 100dvw !important;
}

.mat-divider {
  border-top-color: #e9eaeb !important;
}

// Add border to <mat-menu>, used for example in <ds-user-avatar-menu>.
.mat-mdc-menu-panel {
  margin-top: 6px;
  padding: 0 !important;
  border: 1px solid #e9eaeb !important;
}

// Add little padding to <mat-menu>.
.mat-mdc-menu-content {
  padding: 6px 0 !important;
}
