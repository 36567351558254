@use "@angular/material" as mat;

@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables";

@import "./animations";
@import "./backgrounds";
@import "./branding";
@import "./buttons";
@import "./canvas";
@import "./code";
@import "./colors";
@import "./dialog";
@import "./cards";
@import "./forms";
@import "./gradient";
@import "./icons";
@import "./layout";
@import "./links";
@import "./material";
@import "./images";
@import "./menus";
@import "./theme";
@import "./truncate";
@import "./typography";

@import "./responsive.scss";
