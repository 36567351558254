a {
  color: var(--purple);
  text-decoration: none;
}

.a-like {
  cursor: pointer;
  text-decoration: none;
  color: var(--grey-3);
}

.a-like:hover {
  text-decoration: underline;
}
