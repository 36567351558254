@media (max-width: $xl-screen) {
  .hide-below-xl {
    display: none !important;
  }
}

@media (max-width: $lg-screen) {
  .hide-below-lg {
    display: none !important;
  }
}

@media (max-width: $md-screen) {
  .hide-below-md {
    display: none !important;
  }
}

@media (max-width: $sm-screen) {
  .hide-below-sm {
    display: none !important;
  }
}

@media (min-width: $lg-screen) {
  .hide-above-lg {
    display: none !important;
  }
}

@media (min-width: $md-screen) {
  .hide-above-md {
    display: none !important;
  }
}

@media (min-width: $sm-screen) {
  .hide-above-sm {
    display: none !important;
  }
}
