$img-width-sizes: 100, 200, 300, 400, 500;

@each $size in $img-width-sizes {
  .img-mw#{$size} {
    width: 100%;
    height: auto;
    max-width: #{$size}px;
  }
}

.cover {
  object-fit: cover;
  outline-offset: 0px;
  object-position: 50% 50%;
}

$entity-img-width-sizes: 60, 120, 150;

@each $size in $entity-img-width-sizes {
  .entity-img-#{$size} {
    width: #{$size}px;
    height: #{$size}px;
    object-fit: cover;
    outline-offset: 0px;
    object-position: 50% 50%;
    border-radius: var(--ds-border-radius);
  }
}

.card-img {
  width: 100%;
  height: 60px;
  object-fit: cover;
  outline-offset: 0px;
  object-position: 50% 50%;
  border-radius: var(--ds-border-radius);
}
