@use "@angular/material" as mat;

$my-primary: mat.m2-define-palette(mat.$m2-purple-palette);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

@include mat.all-component-themes($my-theme);

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}
