// Screen breakpoints.
//
// Note: media queries are resolved before CSS is applied, so we
// need to define them as SASS variables.
$xs-screen: 420px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Content-related width.
$main-content-mw: 1000px;

:root {
  // Border
  --ds-border-radius: 16px;

  // Gradient
  --ds-gradient: linear-gradient(45deg, #9c27b0, #ff5cb6, #9c27b0);

  // Padding
  --ds-xs-padding: 4px;
  --ds-sm-padding: 8px;
  --ds-md-padding: 16px;
  --ds-lg-padding: 32px;

  // Box shadow
  --ds-box-shadow: 0 0 var(--ds-sm-padding) 0 var(--border-grey);

  @media (max-width: $sm-screen) {
    --ds-xs-padding: 2px;
    --ds-sm-padding: 4px;
    --ds-md-padding: 10px;
    --ds-lg-padding: 16px;
    --ds-border-radius: 16px;
  }
}
