* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  // Prevent overscroll: this is a mobile web app.
  overscroll-behavior: none;
  // Word break by default.
  word-break: break-word;
}

body {
  margin: 0;
}

// Grid
@for $i from 3 through 6 {
  .grid-#{$i} {
    display: grid;
    grid-template-columns: repeat(#{$i}, 1fr);
    grid-gap: 16px;
  }
}

// Dialogs
.cdk-overlay-pane {
  @media (max-width: $md-screen) {
    max-width: 96vw !important;
  }
}
