// Fonts
@font-face {
  font-family: lemonade;
  src: url(../../assets/fonts/lemonade-party.woff2) format("opentype");
}

.lato {
  font-family: "Lato", sans-serif !important;
}

// Subtitle for h1
.subtitle-1 {
  font-size: 1.35rem;
  font-weight: 400;
  line-height: 1.35rem;
  letter-spacing: -0.017em;
  color: var(--grey-2);
}

// Subtitle for h2
.subtitle-2 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.014em;
  color: var(--grey-2);
}

.title-2 {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-3);
  letter-spacing: -0.011em;
}

.body-1 {
  font-size: 1rem;
  line-height: 17px;
  font-weight: 400;
  color: var(--grey-2);
  letter-spacing: -0.006em;
}

.body-2 {
  font-size: 0.9rem;
  line-height: 15px;
  font-weight: 400;
  color: var(--grey-2);
  letter-spacing: 0em;
}

.caption-1 {
  font-size: 0.9rem;
  line-height: 15px;
  font-weight: 400;
  color: var(--grey-1);
  letter-spacing: 0em;
}

.caption-2 {
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 500;
  color: var(--grey-1);
  letter-spacing: 0em;
}

.font-lemonade {
  font-family: lemonade !important;
}

$my-primary: mat.m2-define-palette(mat.$m2-purple-palette);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette);

// Typography levels
$my-typography: mat.m2-define-typography-config(
  $font-family: Inter,
  $headline-5:
    mat.m2-define-typography-level(24px, 29px, 500, $letter-spacing: -0.019em),
  $headline-6:
    mat.m2-define-typography-level(20px, 24px, 500, $letter-spacing: -0.017em),
  $subtitle-1:
    mat.m2-define-typography-level(18px, 21px, 500, $letter-spacing: -0.014em),
  $subtitle-2:
    mat.m2-define-typography-level(16px, 20px, 500, $letter-spacing: -0.011em),
  $body-1:
    mat.m2-define-typography-level(18px, 20px, 400, $letter-spacing: -0.011em),
  $body-2:
    mat.m2-define-typography-level(16px, 24px, 400, $letter-spacing: -0.006em),
  $caption:
    mat.m2-define-typography-level(12px, 15px, 300, $letter-spacing: 0em)
);

@include mat.all-component-typographies($my-typography);
