// Rules for button sizes.
$buttonInfo: (
  "mini": 12px,
  "small": 18px,
  "medium": 24px,
  "normal": 32px,
  "large": 48px
);
$button-ratio: 0.8;
$icon-button-ratio: 0.666;

@each $name, $size in $buttonInfo {
  .mat-mdc-button.#{$name} {
    width: $size !important;
    height: $size !important;
    line-height: $size !important;
    min-width: $size !important;

    .mat-icon {
      font-size: $size * $button-ratio !important;
      width: $size * $button-ratio !important;
      height: $size * $button-ratio !important;
      line-height: $size * $button-ratio !important;
      margin-right: 0 !important;
    }
  }
}
@each $name, $size in $buttonInfo {
  .mat-mdc-icon-button.#{$name} {
    width: $size !important;
    height: $size !important;
    padding: 0px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role="img"] {
      width: $size * $icon-button-ratio;
      height: $size * $icon-button-ratio;
      font-size: $size * $icon-button-ratio;

      svg {
        width: $size * $icon-button-ratio;
        height: $size * $icon-button-ratio;
      }
    }

    .mat-mdc-button-touch-target {
      width: $size !important;
      height: $size !important;
    }
  }
}

// Override Material spacing and font sizes in buttons.
html {
  --mdc-extended-fab-label-text-tracking: 0;
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-protected-button-label-text-tracking: 0;
  --mdc-outlined-button-label-text-tracking: 0;
  --mdc-extended-fab-label-text-size: 1rem;
  --mdc-filled-button-label-text-size: 1rem;
  --mdc-protected-button-label-text-size: 1rem;
  --mdc-outlined-button-label-text-size: 1rem;
}
