:root {
  // Colors
  --green: #4caf50;
  --grey: #9e9e9e;
  --light-grey: #f5f5f5;
  --purple: #9c27b0;
  --orange: #ff9800;
  --red: #db4437;
  --yellow: #f4b400;
  --white: #fff;
  --light-salmon: #f7eef9;

  // Greys
  --bg-grey: #f7f7f7;
  --bg-grey-2: rgb(0, 0, 0, 0.07);
  --bg-grey-3: rgb(0, 0, 0, 0.12);
  --border-grey: rgb(0, 0, 0, 0.12);
  --grey-1: rgb(0, 0, 0, 0.54);
  --grey-2: rgb(0, 0, 0, 0.73);
  --grey-3: rgb(0, 0, 0, 0.87);
  --grey-4: rgb(0, 0, 0, 0.95);

  --primary-color: #9c27b0;
  --primary-light: #ba68c8;
  --primary-dark: #7b1fa2;
  --complementary-color: #27b09c;
  --analogous-color1: #b0279c;
  --analogous-color2: #b027ba;
  --triadic-color1: #b09c27;
  --triadic-color2: #27b09c;
  --primary-50: #f3e5f5;
  --primary-100: #e1bee7;
  --primary-200: #ce93d8;
  --primary-300: #ba68c8;
  --primary-400: #ab47bc;
  --primary-500: #9c27b0;
  --primary-600: #8e24aa;
  --primary-700: #7b1fa2;
  --primary-800: #6a1b9a;
  --primary-900: #4a148c;

  --green-color: #4caf50;
  --green-light: #81c784;
  --green-dark: #388e3c;
  --green-50: #e8f5e9;
  --green-100: #c8e6c9;
  --green-200: #a5d6a7;
  --green-300: #81c784;
  --green-400: #66bb6a;
  --green-500: #4caf50;
  --green-600: #43a047;
  --green-700: #388e3c;
  --green-800: #2e7d32;
  --green-900: #1b5e20;

  --red-color: #f44336;
  --red-light: #e57373;
  --red-dark: #d32f2f;
  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #ef5350;
  --red-500: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;
  --red-800: #c62828;
  --red-900: #b71c1c;

  --orange-color: #ff9800;
  --orange-light: #ffb74d;
  --orange-dark: #f57c00;
  --orange-50: #fff3e0;
  --orange-100: #ffe0b2;
  --orange-200: #ffcc80;
  --orange-300: #ffb74d;
  --orange-400: #ffa726;
  --orange-500: #ff9800;
  --orange-600: #fb8c00;
  --orange-700: #f57c00;
  --orange-800: #ef6c00;
  --orange-900: #e65100;

  --blue-color: #2196f3;
  --blue-light: #64b5f6;
  --blue-dark: #1976d2;
  --blue-50: #e3f2fd;
  --blue-100: #bbdefb;
  --blue-200: #90caf9;
  --blue-300: #64b5f6;
  --blue-400: #42a5f5;
  --blue-500: #2196f3;
  --blue-600: #1e88e5;
  --blue-700: #1976d2;
  --blue-800: #1565c0;
  --blue-900: #0d47a1;

  --yellow-color: #ffeb3b;
  --yellow-light: #fff176;
  --yellow-dark: #fbc02d;
  --yellow-50: #fffde7;
  --yellow-100: #fff9c4;
  --yellow-200: #fff59d;
  --yellow-300: #fff176;
  --yellow-400: #ffee58;
  --yellow-500: #ffeb3b;
  --yellow-600: #fdd835;
  --yellow-700: #fbc02d;
  --yellow-800: #f9a825;
  --yellow-900: #f57f17;

  --grey-color: #bdbdbd;
  --grey-light: #fafafa;
  --grey-dark: #9e9e9e;
  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  // :hover and :active colors
  --hover-outline: #bbb;
  --active-outline: var(--purple);
}

$colors: "green", "grey", "purple", "light-grey", "orange", "red", "yellow",
  "white";

@each $color in $colors {
  .#{$color} {
    color: var(--#{$color}) !important;
  }

  .bg-#{$color} {
    background-color: var(--#{$color}) !important;
  }
}
