.code-card {
  font-family: "Roboto mono", monospace;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: var(--ds-md-padding);
  border-radius: var(--ds-border-radius);
  background-color: var(--bg-grey-2);
  animation: fadeIn ease 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
// Class for inline code snippets

.code {
  font-family: "Roboto mono", monospace;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: var(--bg-grey-2);
}
