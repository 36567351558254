mat-dialog-content {
  // We intentionally use 600px vs. 768px here as there is a big margin and padding
  // due to CDK backdrop.
  width: 800px;
  font-size: 14px !important;

  @media (max-width: $md-screen) {
    max-width: 100%;
  }
}

@media (max-width: $sm-screen) {
  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    padding: var(--ds-sm-padding) var(--ds-md-padding) !important;
  }
}

.mat-mdc-dialog-content {
  max-height: 75vh !important;
}

mat-dialog-content form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

mat-dialog-content mat-form-field {
  width: 100%;
  margin-bottom: 12px;
}

mat-dialog-content tags-form-field {
  width: 100%;
}

// Add border-radius to the dialog container.
.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--ds-border-radius) !important;
}

// Add padding to the mat-dialog action buttons.
.mat-mdc-dialog-actions {
  padding: var(--ds-md-padding) !important;
}

// Change the style of mat-dialog title.
.mat-mdc-dialog-container .mdc-dialog__title {
  color: var(--white) !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 400%
  ) !important;
  background-color: var(--purple) !important;
  // padding: 16px 24px !important;
}

.close-dialog-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
}
